import NavbarLayout from "./NavbarLayout";
import {Outlet} from "react-router-dom";
import DaySentence from "../exInterface/DaySentence";
import {cardWord2} from "../constEnums";

function BlogLayout() {
    return (
        <>
            <NavbarLayout/>
            <>
                <div className="container-fluid center">

                    <div className="row">

                        <div className="col-sm-0 col-md-2 col-lg-2 col-xl-2  col-xxl-2
                                        d-none d-md-block
                                        p-3"/>

                        <div className="col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 p-3">
                            <div className="row">
                                <div className="container mt-3">
                                    <Outlet/>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-sm-0 col-md-2 col-lg-3 col-xl-3 col-xxl-3 p-3 d-none d-md-block">

                            <div style={{position: "fixed"}}>
                                <div className="card text-center border-light col-6  shadow " style={{
                                    position: "relative",
                                    top: 100,
                                }}>
                                    <img src="/image/tou.jpg" className="card-img-top" alt="头像"/>
                                    <div className="card-body">
                                        <span className="card-text text-muted" style={{height: 80, cursor: "default"}}>
                                            <div className="text-truncate3"><DaySentence/></div>
                                        </span>
                                    </div>
                                    <span className="pacificoFont270"
                                          style={{cursor: "default", fontSize: "20px"}}>{cardWord2}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        </>
    );
}

export default BlogLayout;