import {Outlet} from "react-router-dom";
import {cardWord2} from "../constEnums";
import DaySentence from "../exInterface/DaySentence";
import {useEffect, useRef, useState} from "react";

export default function BodyLayout() {

    const ref = useRef(0);
    const [heigth, setHeght] = useState(0);
    const [cusLoop, setCusLoop] = useState(0);
    const [fz, setfa] = useState(false);

    useEffect(() => {
        let h = ref.current.clientHeight;
        let winHeight = document.documentElement.clientHeight;
        let winWidth = document.documentElement.clientWidth;
        setHeght(winHeight - h - 41)

        let cuh = winHeight;
        let cuw = winWidth;
        (cuh > cuw) ? setCusLoop(cuh * (1 / 4)) : setCusLoop(cuw * (1 / 4))

    }, [fz]);

    window.addEventListener('resize', getDirection) //监听屏幕旋转触发方法

    function getDirection() {
        setfa(!fz);
    }


    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 p-0 " ref={ref}>
                        <img src="/image/bg.png" className="fake-home-background" alt="pic9"/>

                        <div className="home-head">
                            <div id="home-info" className="home-info"
                                 style={{width: `${cusLoop}px`, height: `${cusLoop}px`}}>
                                    <span className="loop"
                                          style={{width: `${cusLoop}px`, height: `${cusLoop}px`}}></span>
                                <span className="loop"
                                      style={{width: `${cusLoop}px`, height: `${cusLoop}px`}}></span>
                                <span className="loop"
                                      style={{width: `${cusLoop}px`, height: `${cusLoop}px`}}></span>
                                <span className="loop"
                                      style={{width: `${cusLoop}px`, height: `${cusLoop}px`}}></span>
                                <a href="#toBody">
                                    <span className="info" style={{width: `${cusLoop}px`, height: `${cusLoop}px`}}>
                                        <div className="wrap">
                                            <h1 className="wraph1"
                                                style={{color: "rgb(102, 175, 239)"}}>vousembrasser</h1>
                                        </div>
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="row" style={{marginTop: `${heigth - 90}px`}}>
                    {/*<div className="row">*/}
                    <div className="col-sm-0 col-md-2 col-lg-2 col-xl-2  col-xxl-2
                                        d-none d-md-block p-3"></div>


                    <div className="col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 p-3">
                        <div className="container mt-3" id="toBody" style={{height: 50}}></div>

                        <div className="container mt-3">
                            <Outlet/>
                        </div>
                    </div>


                    <div className="col-sm-0 col-md-2 col-lg-3 col-xl-3 col-xxl-3 p-3 d-none d-md-block">
                        <div className="sticky-top" style={{top: 10}}>
                            <div className="card text-center border-light col-6  shadow" style={{
                                position: "relative",
                                top: 180
                            }}>
                                <img src="/image/tou.jpg" className="card-img-top" alt="头像"/>
                                <div className="card-body">
                                     <span className="card-text text-muted" style={{height: 80, cursor: "default"}}>
                                            <div className="text-truncate3"><DaySentence/></div>
                                     </span>
                                </div>
                                <span className="pacificoFont270"
                                      style={{cursor: "default", fontSize: "20px"}}>{cardWord2}</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}