import {useState} from "react";


export default function ShowAll(props) {
    const {
        showTxt,
    } = props;


}
