import NavbarLayout from "./layout/NavbarLayout";
import BodyLayout from "./layout/BodyLayout";

function App() {
    return (
        <>
            <NavbarLayout/>
            <BodyLayout/>
        </>
    );
}

export default App;
