import {blogKvsTemp} from "./constEnums";
import React, {useEffect, useState} from "react";
import {Badge, Pagination} from "antd";
import ReactMarkdown from "react-markdown";
import {Link} from "react-router-dom";

export default function Home() {

    const [head, setHead] = useState([{}]);
    const [pageNumCu, setPageNumCu] = useState(1);
    const [ribbonColor, setRibbonColor] = useState("");

    useEffect(() => {
        load(pageNumCu)

    }, [pageNumCu]);

    function load(pageNum) {
        const heads = [];
        for (let i = pageNum * 10 - 10; (i < pageNum * 10) && i < blogKvsTemp.length; i++) {
            const readmePath = require((blogKvsTemp[i].value) + "");
            fetch(readmePath).then((response) => response.text()).then((textTemp) => {
                let b = textTemp.indexOf("---");
                let urls = blogKvsTemp[i].key;
                let e = textTemp.indexOf("---", b + 3)
                let con = textTemp.substring(b + 3, e)
                textTemp = textTemp.substring(e + 3)
                for (; ;) {
                    if (textTemp.startsWith("\r\n")) {
                        textTemp = textTemp.replace(RegExp("[\r\n]*"), "");
                    } else if (textTemp.startsWith("\r")) {
                        textTemp = textTemp.replace(RegExp("[\r]*"), "");
                    } else if (textTemp.startsWith("\n")) {
                        textTemp = textTemp.replace(RegExp("[\n]*"), "");
                    } else {
                        break;
                    }
                }
                con = JSON.parse(con)
                heads.push({con, textTemp, urls})
                setHead([...heads])
            })
        }
    }

    function handlePageOnChange(page, pageSize) {
        setPageNumCu(page);
    }

    function handleMouseEnter(index) {
        setRibbonColor("pink")
        let id = "ribbonId" + index;
        let box = document.getElementById(id);
        box.children[0].children[0].className = "ant-ribbon ant-ribbon-placement-end ant-ribbon-color-pink";
    }

    function handleMouseMove(index) {
        setRibbonColor("pink")
        let id = "ribbonId" + index;
        let box = document.getElementById(id);
        box.children[0].children[0].className = "ant-ribbon ant-ribbon-placement-end";
    }

    return (

        <>
            <h5 className="card-title">{head[1]?.title}</h5>
            <div className="container">
                {head.map((item, index) => {
                    return (
                        <Link to={`/dwd/${item.urls}`} className="nav-link">
                            <div id={`ribbonId${index}`} className="card rounded-4 border-0 border-light shadow "
                                 style={{height: "200px", marginTop: "30px"}} key={index}
                                 onMouseEnter={() => handleMouseEnter(index)}
                                 onMouseLeave={() => handleMouseMove(index)}>
                                <Badge.Ribbon id="ribbonId" text={item.con?.tags}>

                                </Badge.Ribbon>

                                <h1 className="card-title" style={{
                                    textAlign: "center",
                                    marginTop: "5px",
                                    color: "#66afef"
                                }}>{item.con?.title}</h1>


                                <div className="card-body overflow-hidden" style={{marginTop: "-10px"}}>
                                    <div className="col-12">
                                    <span className=" card-text text-truncate3"
                                          style={{fontSize: "15px", color: "#6c757d"}}>
                                    <ReactMarkdown
                                        children={item.textTemp}
                                        components={{
                                            code({node, inline, className, children, ...props}) {
                                                return <></>
                                            },
                                            img({node, inline, className, children, ...props}) {
                                                return <></>
                                            },
                                            h1({node, inline, className, children, ...props}) {
                                                return <></>
                                            },
                                            h2({node, inline, className, children, ...props}) {
                                                return <></>
                                            },
                                            hr({node, inline, className, children, ...props}) {
                                                return <></>
                                            },
                                        }}
                                    />
                                </span>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="date" style={{marginLeft: "20px"}}>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                                 fill="currentColor"
                                                 className="date bi bi-calendar-fill icon" viewBox="0 0 16 16">
                                                <path
                                                    d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z"/>
                                            </svg>
                                        </span>
                                        <span style={{verticalAlign: "-2px"}}>{item.con?.date}</span>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    );
                })
                }
                <Pagination size="small" total={blogKvsTemp.length / 10} responsive={true}
                            style={{textAlign: "center", marginTop: "30px"}}
                            onChange={handlePageOnChange}/>
            </div>

        </>
    );
}