export default function Blogs() {

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-1"/>
                    <div className="col-2" style={{marginLeft:" -20px",marginRight:" 50px"}}>
                        <nav id="navbar-example3"
                             className="navbar navbar-light bg-light flex-column align-items-stretch p-3 sticky-top"
                             style={{top: 188}}>
                            <a className="navbar-brand" href="#">Navbar</a>
                            <nav className="nav nav-pills flex-column">
                                <a className="nav-link active" href="#item-1">Item 1</a>
                                <nav className="nav nav-pills flex-column">
                                    <a className="nav-link ms-3 my-1" href="#item-1-1">Item 1-1</a>
                                    <a className="nav-link ms-3 my-1" href="#item-1-2">Item 1-2</a>
                                </nav>
                                <a className="nav-link" href="#item-2">Item 2</a>
                                <a className="nav-link" href="#item-3">Item 3</a>
                                <nav className="nav nav-pills flex-column">
                                    <a className="nav-link ms-3 my-1" href="#item-3-1">Item 3-1</a>
                                    <a className="nav-link ms-3 my-1" href="#item-3-2">Item 3-2</a>
                                </nav>
                            </nav>
                        </nav>
                    </div>
                    <div className="col-8">
                        <div data-bs-spy="scroll" data-bs-target="#navbar-example3" data-bs-offset="0"
                             className="scrollspy-example-2" tabIndex="0">
                            <h4 id="item-1">Item 1</h4>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                            <h5 id="item-1-1">Item 1-1</h5>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                            <h5 id="item-1-2">Item 1-2</h5>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                            <h4 id="item-2">Item 2</h4>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                            <h4 id="item-3">Item 3</h4>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                            <h5 id="item-3-1">Item 3-1</h5>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                            <h5 id="item-3-2">Item 3-2</h5>
                            <p>This is some placeholder content for the scrollspy page. Note that as you scroll down the
                                page, the appropriate navigation link is highlighted. It's repeated throughout the
                                component
                                example. We keep adding some more example copy here to emphasize the scrolling and
                                highlighting.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}