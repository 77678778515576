import React, {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import MdStyle from "./MdStyle";
import {useParams} from "react-router-dom";
import {blogKvsTemp} from "./constEnums";
// import {xcode} from "react-syntax-highlighter/dist/cjs/styles/hljs";

export default function MdFile() {

    const {blogUrls} = useParams();


    const [terms, setTerms] = useState("加载中");
    const [head, setHead] = useState(null);


    useEffect(() => {
        const readmePath1 = require((blogKvsTemp.find(item => item.key === blogUrls).value) + "");
        fetch(readmePath1).then((response) => response.text()).then((text) => {
            let b = text.indexOf("---");

            let e = text.indexOf("---", b + 3)
            let con = text.substring(b + 3, e)

            text = text.substring(e + 3)
            for (; ;) {
                if (text.startsWith("\r\n")) {
                    text = text.replace(RegExp("[\r\n]*"), "");
                } else if (text.startsWith("\r")) {
                    text = text.replace(RegExp("[\r]*"), "");
                } else if (text.startsWith("\n")) {
                    text = text.replace(RegExp("[\n]*"), "");
                } else {
                    break;
                }
            }
            setTerms(text);
            setHead(JSON.parse(con));
        })
    }, [blogUrls]);

    return (
        <>
            <div className="card mb-3 rounded-4 border-light shadow ">
                <div className="card mb-3 border-0">
                    <div className="card-body">
                        <span className="card-text" style={{fontFamily:"'Poppins', 'Noto Sans SC', sans-serif"}}>

                            <ReactMarkdown
                                children={terms}
                                components={
                                    {
                                        pre({node, inline, className, children, ...props}){
                                            return <pre className="mdPre">
                                                {children}
                                            </pre>
                                        },
                                        code({node, inline, className, children, ...props}) {
                                            const match = /language-(\w+)/.exec(className || '')
                                            return match ? (
                                                <>
                                                   <div className="mdCodeContent">
                                                       <SyntaxHighlighter
                                                           children={children}
                                                           style={MdStyle}
                                                           language={match[1]}
                                                           PreTag="div"
                                                           {...props}
                                                       >
                                                       </SyntaxHighlighter>
                                                       <language className="mdLanguage">{match[1]}</language>
                                                   </div>
                                                </>
                                            ) : (
                                                <code className={className} {...props}>
                                                    {children}
                                                </code>
                                            )
                                        },
                                        img({node, inline, className, children, ...props}) {
                                            return <img className={className} {...props}
                                                        style={{height: "100%", width: "100%"}}>
                                                {children}
                                            </img>
                                        },
                                        h1({node, inline, className, children, ...props}) {
                                            return <h1 className="mdH1" {...props}> {children}</h1>
                                        },
                                        h2({node, inline, className, children, ...props}) {
                                            return <h2 className="mdH2" {...props}> {children}</h2>
                                        },
                                        h3({node, inline, className, children, ...props}) {
                                            return <h3 className="mdH3" {...props}> {children}</h3>
                                        },
                                        h4({node, inline, className, children, ...props}) {
                                            return <h4 className="mdH4" {...props}> {children}</h4>
                                        },
                                        h5({node, inline, className, children, ...props}) {
                                            return <h5 className="mdH5" {...props}> {children}</h5>
                                        }
                                    }
                                }
                            />
                    </span>
                    </div>
                </div>
            </div>
        </>

    );
}