export default function Test123() {

    return (
       <>
             <div className="guide-container">
                   THIS IS THE PARENT DIV
                   <aside className="guide-extras">
                         THIS IS THE SIDEBAR
                   </aside>
             </div>
             <div className="guide-container">
                   THIS IS THE PARENT DIV
                   <aside className="guide-extras">
                         THIS IS THE SIDEBAR
                   </aside>
             </div>
       </>

)
    ;
}