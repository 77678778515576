import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./Home";
import NewsResults from "./NewsResults";
import App from "./App";
import NewsLayout from "./NewsLayout";
import BlogLayout from "./layout/BlogLayout";
import MdFile from "./MdFile";

export default function RouterFun() {
    return (
        <Router>
            <Routes>
                <Route element={<App/>}>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/news" element={<NewsLayout/>}>
                        <Route path=":newsTopic" element={<NewsResults/>}/>
                    </Route>
                </Route>
                <Route path="/dwd" element={<BlogLayout/>}>
                    <Route path=":blogUrls" element={<MdFile/>}/>
                </Route>
            </Routes>
        </Router>
    );
}