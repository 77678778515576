import {Link} from "react-router-dom";
import {blogKvs, blogKvsTemp, topics} from "../constEnums";


export default function NavbarLayout() {

    const handleClick = (e) => {
        // e.preventDefault()
        document.getElementById('navbarSupportedContent').classList.remove('show')
    }



    let listItems = topics.map((item, index) => {
        return (
            <li className="nav-item" key={index} onClick={handleClick}>
                <Link to={item.startsWith("!") ? `/news/${item.substring(1)}` : `/${item}`}
                      className="nav-link">{item.startsWith("!") ? item.substring(1) : item}</Link>
            </li>
        );
    })
    let blogItems = blogKvsTemp.map((item, index) => {
        return (
                <Link to={`/dwd/${item.key}`}
                      className="dropdown-item " style={{color: "white"}}
                      onClick={handleClick}
                      key={index}
                >{item.key} </Link>
        );
    })

    return (
        <div className="row">
            <nav className="navbar navbar-expand-lg navbar-expand-md navbar-dark bg-dark fixed-top">
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 "/>
                <div className="container-fluid">

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="true" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link to="/" className="navbar-brand pacificoFont">vousembrasser</Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className='navbar-nav me-auto mb-2 mb-lg-0' >

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button"
                                   aria-expanded="false" >我的博客 看看?</a>
                                <ul className="dropdown-menu bg-dark border-0">
                                    {blogItems}
                                </ul>
                            </li>

                            <li className="nav-item" >
                                <Link to="/" className="nav-link">Home</Link>
                            </li>

                            {listItems}


                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}