import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/antd.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/fonts.min.css'
import RouterFun from "./RouterFun";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterFun/>
);