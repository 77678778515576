import {useEffect, useState} from "react";
import {cardWord1} from "../constEnums";
import {Tooltip} from "antd";

export default function DaySentence() {
    const [results, setResults] = useState(cardWord1);
    let output = <div>{results}</div>
    useEffect(() => {
        fetch(`https://api.vvhan.com/api/ian?type=json`)
            .then(res => res.json()).then(obj => {
            obj.success && setResults(obj.data.vhan)
        }).catch(error => {

        })
    }, []);

    return (
        <Tooltip title={output} placement="bottom">
            <div>{results}</div>
        </Tooltip>
    );
}