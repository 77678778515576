export const cardWord1 = "愿识一人心,奔赴向往.";
export const cardWord2 = "vousembrasser";

export  const topics = ['!ReactJS', '!JavaScript', 'dwd'];
export  const blogKvs = ['!ReactJS', '!JavaScript', 'dwd'];

export const blogKvsTemp = [
    {key:"spring",value:"./_mdDid/spring/gateway.md"},
    {key:"es",value:"./_mdDid/elasticsearch/优化/optimazation.md"},
    {key:"linux",value:"./_mdDid/实用linux/vim.md"},
    {key:"构建",value:"./_mdDid/构建/build.md"}
]
